import CardList from './components/card-list/card-list';
import SearchBox from './components/search-box/search-box'
import './App.css';
import { useState, useEffect } from 'react';


const App = () => {
  const [searchField, setSearchField] = useState(''); //[value, setValue]
  const [monsters, setMonsters] = useState([]);
  const [filteredMonsters, setFilterMonsters] = useState(monsters);

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/users')
    .then((response) => response.json())
    .then((users) => setMonsters(users));
  }, []);  //Wenn das array sich ändert wird ausgeführt.
  //Lehres Array ändert sich nie, wird auch nie drauf zugegriffen. Gibt nichtmal ne Variable.
  useEffect(() => {
    const newFilteredMonsters = monsters.filter((monster) => {
      return monster.name.toLocaleLowerCase().includes(searchField);
    });
    
    setFilterMonsters(newFilteredMonsters);
  }, [monsters, searchField])

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  }


  return(
    <div className="App">
          <h1 className='app-title'>Monster Deck</h1>
          <SearchBox
            className='monsters-search-box' 
            onChangeHandler={onSearchChange} 
            placeholder='search monsters'
          />
          <br/>
          {/*<SearchBox
            className='title-search-box' 
            onChangeHandler={onTitleChange} 
            placeholder='set title'
          />  */}
          <CardList monsters={filteredMonsters} />
      </div>
  )
}

export default App;