import './card.css';

const Card = ({ monster: {name, email, id} }) => { //hardcore props inline-destructuring, worx maybe only cuz alread had that structure in parent component
    return (
        <div className="card-container" key={id}>
            <img alt={`monter ${name}`} src={`https://robohash.org/${id}?set=set2&size=180x180`} />
            <h2>{name}</h2>
            <p>{email}</p>
        </div>
    );
};

export default Card;