import './card-list.css';
import Card from '../card/card';

const CardList = ({ monsters }) => (  //props rename to monsters
    <div className="card-list">
        {monsters.map(monster => {
            const {name, email, id} = monster;
            return <Card monster={monster}/>
        })}
    </div>
)

export default CardList;